import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export const UserProfile = () => {
  return (
    <Popover content={<ProfileDropdownMenu />} position={Position.BOTTOM}>
      <Button icon={IconNames.PERSON} minimal />
    </Popover>
  );
};

const ProfileDropdownMenu = () => {
  return (
    <Menu>
      <MenuItem icon={IconNames.SETTINGS} text="Settings" />
      <MenuItem icon={IconNames.LOG_OUT} text="Logout" />
    </Menu>
  );
};
