import {
  Alignment,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading
} from "@blueprintjs/core";
import { Logo } from "../logo/Logo";
import { UserProfile } from "../user/UserProfile";

import "./AppHeader.scss";

export const AppHeader = () => {
  return (
    <Navbar>
      <NavbarGroup align={Alignment.LEFT}>
        <NavbarHeading>
          <Logo />
        </NavbarHeading>
        <NavbarDivider />
        Coming soon...
      </NavbarGroup>

      <NavbarGroup align={Alignment.RIGHT}>
        [User Name]
        <NavbarDivider />
        <UserProfile />
      </NavbarGroup>
    </Navbar>
  );
};
