import { Route, Routes } from "react-router-dom";

import { AuthProvider, ProtectedPage, RequireAuth } from "./auth";

import { AppHeader } from "./components/app-header/AppHeader";

/* Private Modules */

/* Public Modules */
import { MainModule } from "./components/main-module/MainModule";
import { LoginWidget } from "./components/login-widget/LoginWidget";

import { NavigationInventoryModule } from "./components/navigation-inventory-module/NavigationInventoryModule";
import { SingularityApp } from "./components/singularity-app/SingularityApp";

import "./App.scss";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainModule />} />
      <Route path="/login" element={<LoginWidget />} />
      <Route path="/protected/*" element={<ProjectedRoutes />} />
      <Route path="/public/*" element={<PublicRoutes />} />
    </Routes>
  );
};

const ProjectedRoutes = () => {
  return (
    <RequireAuth>
      <ProtectedPage />
    </RequireAuth>
  );
};

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/singularity-app" element={<SingularityApp />} />
      <Route path="/*" element={<NavigationInventoryModule />} />
    </Routes>
  );
};

export const App = () => {
  return (
    <AuthProvider>
      <div className="app">
        <AppHeader />

        <div className="app-body">
          <AppRoutes />
        </div>
      </div>
    </AuthProvider>
  );
};
