import "./Logo.scss";

export const SingularityLogo = () => {
  return (
    <div className="logo-container">
      <svg
        className="singularity-logo"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="168.000000pt"
        height="168.000000pt"
        viewBox="0 0 168.000000 168.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,168.000000) scale(0.100000,-0.100000)">
          <path
            d="M1503 1530 c-17 -20 -30 -20 -675 -20 l-658 0 0 -657 c0 -548 -2
-659 -14 -672 -9 -10 -15 -43 -16 -86 -4 -90 -3 -95 15 -95 12 0 15 17 15 90
l0 90 665 0 666 -1 -1 666 0 665 90 0 c83 0 90 1 90 20 0 18 -7 20 -80 20 -68
0 -83 -3 -97 -20z m-48 -675 l0 -630 -630 0 -630 0 -3 620 c-1 341 0 626 3
633 3 10 135 12 632 10 l628 -3 0 -630z"
          />
          <path
            d="M342 1337 c-22 -23 -22 -24 -22 -493 l0 -471 23 -21 c23 -22 24 -22
493 -22 l471 0 21 23 c22 23 22 24 22 493 l0 471 -23 21 c-23 22 -24 22 -493
22 l-471 0 -21 -23z m968 -492 l0 -475 -475 0 -475 0 0 475 0 475 475 0 475 0
0 -475z"
          />
          <path
            d="M550 843 l0 -288 285 2 286 2 2 286 2 285 -287 0 -288 0 0 -287z
m525 2 l0 -240 -240 0 -240 0 -3 230 c-1 126 0 235 3 242 3 11 55 13 242 11
l238 -3 0 -240z"
          />
        </g>
      </svg>
    </div>
  );
};
