import { Button } from "@blueprintjs/core";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import { useNavigate } from "react-router-dom";

import "./NavigationInventoryModule.scss";

export const NavigationInventoryModule = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const idCellRenderer = (rowIndex: number) => {
    return <Cell>{rowIndex}</Cell>;
  };

  const nameCellRenderer = () => {
    return (
      <Cell>
        <Button
          text="Portable Business Card"
          onClick={() => navigateTo("/public/singularity-app")}
          minimal
        />
      </Cell>
    );
  };

  return (
    <div className="navigation-inventory-module">
      <div className="module-body">
        <Table2 numRows={1}>
          <Column name="ID" cellRenderer={idCellRenderer} />
          <Column name="Name" cellRenderer={nameCellRenderer} />
        </Table2>
      </div>
    </div>
  );
};
