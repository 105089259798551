import LogoSvg from "./logo.svg";
import "./Logo.scss";

export const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoSvg} />
    </div>
  );
};
