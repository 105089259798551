import {
  Alignment,
  Classes,
  Navbar,
  NavbarDivider,
  NavbarGroup
} from "@blueprintjs/core";
import QRCode from "react-qr-code";
import { SingularityLogo } from "./components/logo/Logo";
import "./SingularityApp.scss";

export const SingularityApp = () => {
  return (
    <div className={`singularity-app ${Classes.DARK}`}>
      <div className="app-header">
        <Navbar>
          <NavbarGroup align={Alignment.LEFT}>
            <SingularityLogo />
            <NavbarDivider />
          </NavbarGroup>
        </Navbar>
      </div>
      <div className="app-body">
        <QRCode
          size={200}
          style={{ height: "auto", maxWidth: "200px", width: "100%" }}
          value="jaeism.com"
          viewBox="0 0 200 200"
        />
      </div>
    </div>
  );
};
